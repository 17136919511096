.totalSpend {
  padding: 16px 24px 20px;
  background: #ffffff;
  border: 1px solid #d2d5d8;
  box-sizing: border-box;
  border-radius: 8px;
  .cardLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 120px;
  }
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      h2 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 4px;
      }
      h3 {
        display: flex;
        align-items: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 24px;
        color: #000000;
        small {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          color: #babec3;
          // margin-left: 10px;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      button {
        padding: 2px 8px;
        background: #f1f1f1;
        border-radius: 4px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #00a47c;
        border: 0;
        outline: 0;
        white-space: nowrap;
      }
      h5 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        margin-left: 10px;

        text-transform: uppercase;

        cursor: pointer;
        color: #008060;
      }
    }
  }
  hr {
    border: 1px solid #e4e5e7;
    margin: 12px 0;
  }
  .orderBox {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #8c9196;
      }
      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #202223;
        small {
          color: #babec3;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    h5 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #d72c0d;
      margin-top: 8px;
    }
    h2 {
      margin-top: 8px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8c9196;
    }
    h6 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      color: #2c6ecb;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
