.enableModal {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .leftMain {
    hr {
      border: 1px solid #edeeef;
    }
    background-color: #fff;
    border: 1px solid #edeeef;
    box-shadow: 0px 4px 4px rgba(36, 36, 36, 0.06);
    border-radius: 8px;
    padding: 12px 16px;
    height: 100%;
    width: 600px;
    margin-right: 42px;
    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      color: #000000;
    }
    .settingsItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;
      // margin-bottom: 16px;
      padding: 15px 0;
      .left {
        h4 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          /* identical to box height, or 143% */

          color: #000000;
        }
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 133% */

          /* Icon/Subdued */

          color: #8c9196;
        }
      }
      .right {
        .switch {
          margin-left: 16px;
          position: relative;
          display: inline-block;
          width: 28px;
          height: 8px;

          input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .slider {
              background: #6366f1;
            }

            &:checked + .slider:before {
              -webkit-transform: translateX(15px);
              -ms-transform: translateX(15px);
              transform: translateX(15px);
              background: #a5b4fc;
            }
          }

          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 5px;
            bottom: 0;
            background: #aeb4b9;
            // box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            -webkit-transition: 0.4s;
            transition: 0.4s;

            &:before {
              position: absolute;
              content: "";
              height: 12px;
              width: 12px;
              left: -2px;
              bottom: -2px;
              background: #8c9196;
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
              -webkit-transition: 0.4s;
              transition: 0.4s;
              border-radius: 34px;
            }
          }
        }
      }
    }
    .requestSettings {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-bottom: 16px;
      padding: 15px 0;
      h4{
        font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          
          color: #000000;
      }
      select {
        outline: none;
        border: 0;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        margin-left: 20px;
        background-color: #fff;
        color: #44474a;
        cursor: pointer;
        option {
          font-family: inherit;
        }
      }
    }
    .desc {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #44474a;
      padding: 4px;
      background: #fafbfb;
      width: fit-content;
      margin-bottom: 16px;
    }
    .actions{
display: flex;
align-items: center;
justify-content: space-between;
margin-top: 15px;
      .saveRequest {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding: 8px 16px;
        color: #ffffff;
        background: #00a47c;
        border-radius: 8px;
        border: none;
        outline: none;
        cursor: pointer;
       
        &:hover{
        background: #01644b;
        }
        &:disabled{
          cursor: not-allowed;
          opacity: 0.8;
        }
      }
      h5{
font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #8C9196;
  cursor: pointer;
      }
    }
  }
  .rightMain {
    .whatsapp {
      max-width: 360px;
      width: 100%;
      border-radius: 8px;
        max-height: 550px;
      .topBar {
        border-radius: 6px 6px 0 0;
        display: block;
      }
      .chatContainer{
overflow: hidden;
position: relative;
}
.chatBox {
  .loader{
    width: 100%;
  margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }
transition: 2s all;
scroll-behavior: smooth;
&::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #646464;
  }
        padding: 64px 11px 70px;
        height: 560px;
       
        background: #e8e0d5;
        border-radius: 0 0 8px 8px;
        // display: flex;
        // flex-direction: column;
        // justify-content: flex-end;
        overflow-y: auto;
        .topHead {
          position: absolute;
          top: -0;
          left: 0;
          right: 0;
          width: 100%;
          padding: 16px 12px;
          height: 64px;
          display: flex;
          align-items: center;
          background: #085b53;
          border-radius: 0 0 6px 6px;
          span {
            background: #c4c4c4;
            display: block;
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
          h4 {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            margin: 0 5px 0 8px;
            color: #ffffff;
          }
          svg {
          }
        }
                .btnsLeft {
                  max-width: 282px;
                  .btnTop {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 8px;
                  }
                  .btnFull{
                    width: 100%;
                    margin-bottom: 8px;
                    .btn{
                      width: 100% !important;
                    }
                  }
                  .btn{
                    height: 35px;
                    width: 48%;
                    font-family: "Roboto";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 19px;
                      color: #000000;
                        padding: 8px;
                        background: #fff;
                        border-radius: 4px;
                        text-align: center;


                  }

                }
        .leftMsg {
          padding: 8px;
          max-width: 282px;
          background: #ffffff;
          border-radius: 4px;
          margin-bottom: 8px;
          animation: bottomToTops ease 2s infinite;
          -webkit-animation: topToBottom ease 2s;
          -moz-animation: topToBottom ease 2s;
          -o-animation: topToBottom ease 2s;
          -ms-animation: topToBottom ease 2s;
         .quickReplyFooter{
           border-top: 1px solid #000;
           margin-top: 10px;
           text-align: center;
           display: flex;
           align-items: center;
           padding: 5px 0;
           justify-content: center;
          font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
           svg{
             margin-right: 5px;
           }
         }
          .img{
            margin-bottom: 8px;
            img{
              display: block;
              width: 100%;
              object-fit: contain;
              border-radius: 4px;
            }
          }
          
          p {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
white-space: pre-line;
            color: #000000;
            line-break: anywhere;
          }
          .time {
            margin-top: 4px;
            small {
              margin-left: auto;
              margin-right: 0;
              display: block;
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              text-align: right;

              color: #9da3a7;
            }
          }
        }
        .rightMsg {
          margin-bottom: 8px;
          width: fit-content;
          padding: 8px;
          max-width: 282px;
          background: #d3ffc8;
          border-radius: 4px;
          margin-left: auto;
          border-radius: 4px;
          margin-right: 0;
          .img {
              margin-bottom: 8px;
          
              img {
                display: block;
                width: 100%;
                object-fit: contain;
                border-radius: 4px;
              }
            }
          p {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
          }

          .time {
            margin-top: 4px;
            small {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              text-align: right;
              margin-left: auto;
              margin-right: 0;
              display: block;
              color: #9da3a7;
            }
          }
        }
        .chatInput {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          background: #e8e0d5;
          padding: 5px 10px;
          border-radius: 0 0 6px 6px;
          span{
            height: 48px;
            width: 100%;
              background: #FFFFFF;
              border-radius: 36px;
            display: inline-block;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1200px) {
  .enableModal {
    justify-content: center;
    flex-wrap: wrap;
    .leftMain {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}

@keyframes bottomToTops {
  0% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(0);
  }
}
