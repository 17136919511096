$break-xl: 1280px;
$break-xll: 1200px;
$break-lg: 1024px;
$break-small: 768px;
$break-xs: 480px;
$break-xx: 540px;
$break-ss: 360px;
$break-es: 411px;
$break-large: 1500px;

$primaryColor: #388161;
$primaryLight: #45985b;
$textLight: #637381;
$warning: #ffc107;
$white: #ffffff;
$--p-background-hovered: linear-gradient(
  rgba(223, 227, 232, 0.3),
  rgba(223, 227, 232, 0.3)
);

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap");
// html {
//   overflow-x: hidden;
//   min-width: 320px;
// }
