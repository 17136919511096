.logo {
display: flex;
align-items: center;
margin-bottom: 10px;
margin-left: 20px;
margin-right: 20px;
justify-content: space-between;

    img {
        width: 100%;
        max-width: 160px;
        height: 100%;
        display: block;
        max-height: 55px;
        cursor: pointer;
    }

    .notIcon {
        position: relative;
margin-top: 5px ;
        small {
            position: absolute;
            top: -7px;
            right: -6px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 8px;
            line-height: 24px;
            color: #FFFFFF;
            background: #FF0000;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.userBox {
    display: flex;
    align-items: center;
    margin: 0 auto 20px;
    padding-bottom: 20px;
    width: 85%;
    cursor: pointer;
    border-radius: 6px;
    padding: 3px 15px;
    margin: 0 20px 20px 20px;

    &:hover {
        background-color: rgb(218 223 229 / 30%);
    }

    .img {
        background: #C4C4C4;
        width: 35px;
        min-width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
    }

    .right {

        h5 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #FFFFFF;
        }

        h6 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            display: block;
            color: #DEDEDE;
        }
    }
}