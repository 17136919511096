.onBoarding{
    background: #f3f7fb;
    width: 100%;
    min-height: 100%;
    .container{
        .parent{
display: flex;
align-items: center;
justify-content: center;
min-height: 100vh;
padding: 25px;
        }
    }
}

.onBoardingStep{
    max-width: 680px;
    width: 100%;
    .top{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */
        
        text-align: center;
        
        /* Icon/Success */
        
        color: #007F5F;
    }
    .stepProgress{
        text-align: center;
        h5{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            margin-bottom: 8px;
        }
        .progressBar{
            margin: 0 auto;
            height: 9px;
            width: 100%;
            max-width: 351px;
            background: #EDEEEF;
            border-radius: 12px;
            position: relative;
            span{
background: #347C84;
border-radius: 12px;
height: 100%;
display: inline-block;
position: absolute;
left: 0;
top: 0;
bottom: 0;
right: 0;
// animation: progressBar 3s ease-in-out;
animation-fill-mode:both;
            }
        }
    }
    .stepBox{
        margin-top: 26px;
        background: #FFFFFF;
        border: 1px solid #F1F2F3;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 28px 36px;
    animation: fadein 1s;
    // min-height: 270px;
    }
    .stepBottom{
margin-top: 26px;
text-align: center;
    }
    }

    .skip{
        h6{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #6D7175;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }

.stepOne{
    .logo{
        margin: 0 auto;
        width: fit-content;
        img{
            width: 47px;
            height: 47px;
            display: block;
        }
    }
    h5{
        margin-top: 16px;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
color: #000000;
text-align: center;
margin-bottom: 4px;
    }
    p{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
text-align: center;
color: #717171;
margin-bottom: 26px;
    }
    .nextBtn{
        margin: 0 auto;
display: flex;
justify-content: center;
align-items: center;
width: 109px;
height: 36px;

background: #00A47C;
border-radius: 8px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #FFFFFF;
border: 0;
outline: none;
cursor: pointer;
&:hover{
    background: #01644b;
}
    }
}

.stepTwo{
    text-align: justify;
   padding: 20px 34px;
   height: 200px;
   .loader{
       display: flex;
       justify-content: center;
       align-items: center;
       width: 100%;
       height: 100%;
       h5{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #8C9196;
       }
   }
    h5{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
margin-bottom: 2px;
color: #000000;
    }
    p{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
/* or 133% */


/* Action Secondary/Depressed */

color: #6D7175;
    }
    .getNumber{
        margin: 12px 0 24px 0;
        text-align: end;
        position: relative;
        .formBox{

            display: flex;
            height: 43px;
        }
.countryCode{
    display: flex;
        align-items: center;
    width: 128px;
    max-width: 128px;
        overflow: hidden;
    padding: 9px 10px;
background: #FFFFFF;
border: 1px solid #D2D5D8;
box-sizing: border-box;
border-radius: 8px;
height: 100%;
width: 100%;
cursor: pointer;
select{
cursor: pointer;
   margin-left: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    background-color: #fff;
        outline: none;
        appearance: none;
        border: none;
    }
}
.mobile{
    width: calc(100% - 128px);
    padding-left: 9px;
    input{
/* hide arrows
 Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0 !important;
    }

    /* Firefox */
    &[type=number] {
        -moz-appearance: textfield !important;
    }

        appearance: none;
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        
        border: 1px solid #D2D5D8;
        box-sizing: border-box;
        border-radius: 8px;
appearance: none;
padding: 8px 9px;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
outline: none;
color: #000000;
    }
}
    }
    .error{
color: rgb(231, 38, 38);
font-size: 10px;
font-weight: 400;
position: absolute;
right: 0;
    }
    .sendDemo{
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 36px;

background: #00A47C;
border-radius: 8px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #FFFFFF;
border: 0;
outline: none;
cursor: pointer;
&:hover {
    background: #01644b;
}
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes progressBar {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

.stepThree{
    padding: 56px 75px;
    text-align: center;
    h5{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
color: #1A1C1D;
margin-bottom: 4px;
    }
    h6{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
color: #000000;
margin-bottom: 28px;
    }
}

.productCard{
    display: flex;
    background: #FFFFFF;
    max-width: 470px;
    width: 100%;
    
    border: 1px solid #C9CCCF;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 13px 16px;
    .image{
        margin-right: 15px;
img{
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 4px;
}
    }
    .content{
        text-align: left;
h4{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */


color: #000000;
}
p{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
/* identical to box height, or 133% */


/* Action Secondary/Depressed */

color: #6D7175;
}
    }
}

.stepFour{
    display: flex;
    flex-direction: column;
    align-items:center ;
    .top{
        margin-bottom: 34px;
        text-align: left;
        display: flex;
        align-items: center;
        .image{
            margin-right: 5px;
            img{
display: block;
            }
        }
        .content{
            h5{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
color: #1A1C1D;
margin-bottom: 4px;
            }
            h6{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
/* identical to box height, or 133% */

text-align: center;

color: #000000;
            }

        }
    }
}
.quickReplies{
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .replyItem{
border: 1px solid #EDEEEF;
box-sizing: border-box;
border-radius: 8px;
padding: 0 30px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
margin-right: 15px;
color: #999EA4;
margin-bottom: 15px;
display: flex;
align-items: center;
height: 36px;
justify-content: center;
svg{
    margin-right: 10px;
    margin-top: 2px;
}
&.active{
border: 2px solid #458FFF;
font-weight: 500;
}
    }
}
.resend{
    h5{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        
        color: #000000;
        small{
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
           cursor: pointer;
            color: #003D2C;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}

.stepFive{
    display: flex;
    flex-direction: column;
    align-items: center;
    .desc{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
       margin-top: 15px;
        text-align: center;
        background: #F6F6F7;
        border-radius: 8px;
        padding: 6px;
        color: #202223;
        margin-bottom: 20px;
    }
    .enableBtn{
        margin: 0 auto;
        button{

            display: flex;
                justify-content: center;
                align-items: center;
                width: fit-content;
                height: 36px;
                padding: 0 16px;
                background: #00A47C;
                border-radius: 8px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #FFFFFF;
                border: 0;
                outline: none;
                cursor: pointer;
            
                &:hover {
                    background: #01644b;
                }
                svg{
                    margin-left: 10px;
                }
        }
        }
    
}
.enableStore{
    text-align: center;
    h4{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */


margin-bottom: 16px;
color: #000000;
    }
    button{
        margin: 0 auto;
display: flex;
justify-content: center;
align-items: center;
width: fit-content;
height: 36px;
padding: 0 16px;
background: #00A47C;
border-radius: 8px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #FFFFFF;
border: 0;
outline: none;
cursor: pointer;
&:hover {
    background: #01644b;
}
    }
}