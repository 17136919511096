.pendingSteps{
    margin-bottom: 16px;
    padding: 16px 24px;
    background: #FFFFFF;
    border: 1px solid #D2D5D8;
    box-sizing: border-box;
    border-radius: 8px;
    .cardLoader {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            min-height: 100px;
        }
    h3{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #8C9196;
    }
    .progress{
        margin: 12px 0 20px 0;
    }
    .steps{
        .stepItem{
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            h5{
                margin-left: 14px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #000000;
            }
            &:last-child{
                margin-bottom: 0;
            }
            svg{
                min-width: 20px;
            }
            &.active{
                h5{
                    text-decoration-line: line-through;
                    color: #606060;
                }
                svg path{
                    fill: #007F5F;
                }
            }
        }
    }
}