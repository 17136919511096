.setupModal {
    .leftMain {
        hr {
            border: 1px solid #edeeef;
        }

        background-color: #fff;
        border: 1px solid #edeeef;
        box-shadow: 0px 4px 4px rgba(36, 36, 36, 0.06);
        border-radius: 8px;
        padding: 12px 16px;
        height: 100%;
        width: 600px;
        margin-right: 42px;

        h3 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            color: #000000;
        }

        .settingsItem {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 0;
            // margin-bottom: 16px;
            padding: 15px 0;

            .left {
                h4 {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    color: #000000;
                }

                p {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    margin-top: 5px;
                    padding: 5px 0;
                    select{
                        margin: 0 3px;
                        outline: 0;
                        border: 0;
                    }

                    color: #8c9196;
                }
            }

            .right {
                .switch {
                    margin-left: 16px;
                    position: relative;
                    display: inline-block;
                    width: 28px;
                    height: 8px;

                    input {
                        opacity: 0;
                        width: 0;
                        height: 0;

                        &:checked+.slider {
                            background: #6366f1;
                        }

                        &:checked+.slider:before {
                            -webkit-transform: translateX(15px);
                            -ms-transform: translateX(15px);
                            transform: translateX(15px);
                            background: #a5b4fc;
                        }
                    }

                    .slider {
                        position: absolute;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 5px;
                        bottom: 0;
                        background: #aeb4b9;
                        // box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
                        border-radius: 10px;
                        -webkit-transition: 0.4s;
                        transition: 0.4s;

                        &:before {
                            position: absolute;
                            content: "";
                            height: 12px;
                            width: 12px;
                            left: -2px;
                            bottom: -2px;
                            background: #8c9196;
                            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
                            -webkit-transition: 0.4s;
                            transition: 0.4s;
                            border-radius: 34px;
                        }
                    }
                }
            }
        }

        .requestSettings {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // margin-bottom: 16px;
            padding: 15px 0;

            h4 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;

                color: #000000;
            }

            select {
                outline: none;
                border: 0;
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                margin-left: 20px;
                background-color: #fff;
                color: #44474a;
                cursor: pointer;

                option {
                    font-family: inherit;
                }
            }
        }

        .desc {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #44474a;
            padding: 4px;
            background: #fafbfb;
            width: fit-content;
            margin-bottom: 16px;
        }

        .saveRequest {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            padding: 8px 16px;
            color: #ffffff;
            background: #054D47;
            border-radius: 8px;
            margin-top: 15px;
            border: none;
            outline: none;
            cursor: pointer;
            margin-right: 0;
            margin-left: auto;

            &:hover {
                background: #021413;
            }

            &:disabled {
                cursor: not-allowed;
                opacity: 0.8;
            }
        }
                .cancel {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    padding: 8px 16px;
                    color: #ffffff;
                    background: #054D47;
                    border-radius: 8px;
                    margin-top: 15px;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    margin-right: 10px;
                   
        
                    &:hover {
                        background: #021413;
                    }
        
                    &:disabled {
                        cursor: not-allowed;
                        opacity: 0.8;
                    }
                }
                                .agree {
                                    // margin-left: 10px;
                                    font-family: "Inter";
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 20px;
                                    padding: 8px 16px;
                                    color: #ffffff;
                                    background: #00a47c;
                                    border-radius: 8px;
                                    margin-top: 15px;
                                    border: none;
                                    outline: none;
                                    cursor: pointer;
                                    
                
                                    &:hover {
                                        background: #01644b;
                                    }
                
                                    &:disabled {
                                        cursor: not-allowed;
                                        opacity: 0.8;
                                    }
                                }
    }
    .actions{
        display: flex;
        align-items: center;
    }

   .btnLoader{
    width: 117px;
    padding: 8px 16px;
    color: #ffffff;
    background: #00a47c;
    border-radius: 8px;
    margin-top: 15px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
height: 36px;
   }
}

@media all and (max-width: 1200px) {
    
    
}