.totalRating{
    margin-bottom: 16px;
    background: #FFFFFF;
    padding: 16px 24px;
    border: 1px solid #D2D5D8;
    box-sizing: border-box;
    border-radius: 8px;
    .cardLoader {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            min-height: 150px;
        }
    .head{
        h2{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
margin-bottom: 4px;
color: #000000; 
        }
        h3{
            display: flex;
            align-items: baseline;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 24px;
            color: #000000;
            small{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 24px;
margin-left: 5px;
color: #BABEC3;
            }
        }
    }
    hr{
        margin: 17px 0 20px 0;
        border: 1px solid #E4E5E7;
    }
    .stars{
        .starItem{
            display: grid;
            align-items: center;
            grid-template-columns: 34px 1fr 30px;
margin-bottom: 8px;
&:last-child{
    margin-bottom: 0;
}
            .star{
                display: flex;
                align-items: center;
svg{
margin-right: 2px;
}
            }
            .progress{
padding: 0 16px;
            }
            .value{
            }
        }
    }
    h5{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        color: #2C6ECB;
        &:hover {
                text-decoration: underline;
            }
    }
}