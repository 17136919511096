@import "../../assets/css/global.scss";
.onboardStepsHead{
    .step{
        background:#f8eee0;
        border-radius: 100%;
        color: #e6932e;
        width: 30px;
        height: 30px;
        align-items: center;
        display: flex;
        justify-content: center;
        font-weight: 500;
        &.active{
            background: #5dc675;
            color: #fff;
        }
        &.completed{
            background: #e5f6e8;
            color: #5dc675;
            svg{
                fill:#5dc675;
            }
        }
    }

    .stepCount{
        padding-top: 1em;
        color: #459759;
        font-weight: bold;
        font-size: 1.2em;
    }
}

.enableButton{
    border: none;
    background: #388161;
    color: #fff;
    padding: .6em 1em;
    cursor: pointer;
    font-weight: 500;
    border: 0.1rem solid var(--p-border, #388161);
    border-radius: 3px;
    &.enabled{
        background: #fff;
        color: #000;
        background: #f4f6f8 !important;
        border: 0.1rem solid var(--p-border, #c4cdd5);
        box-shadow: 0 1px 0 0 rgb(22 29 37 / 5%);
    }
    &:focus{
        outline: none;
    }
}
.stepBody{
    padding: 1em 0;
    display: none;
    &.show{
        display: block;
    }
}

.footer{
    margin-top: 3em;
    button{
        border: none;
        cursor: pointer;
        padding: 6px 12px;
        border-radius: 6px;
    }
    .actionButton{
        color:$primaryColor;
        font-weight: 500;
        font-size: 1em;
        background: #e5f6e8;
        border:1px solid rgba(69 152 91 / 60%);
        cursor: pointer;
        &:focus{
            outline-color: $primaryLight;
        }
    }
    .normalButton{
        color: rgba(33 43 54 / 77%);
        background: none;
        border: 1px solid rgba(0 0 0 / 10%);
        &:focus{
            outline-color:rgba(0 0 0 / 40%);
        }
    }
}

.schedule_card_section {
    display: grid;
    grid-template-columns: 4fr 5fr;
    grid-gap: 30px;
    @media screen and (max-width:$break-xs){
        grid-template-columns: 1fr;
    }
}

.left_aligned_grid{
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 10px;
    align-items: center;
    @media screen and (max-width:$break-xs) {
        grid-template-columns: 1fr;
      }
}
.right_aligned_grid{
    display: grid;
    grid-template-columns:auto 1fr;
    grid-gap: 10px;
    align-items: center;
    @media screen and (max-width:$break-xs) {
        display: grid;
        grid-template-columns: 1fr;
      }
}
ul.features {
    list-style: none;
    display: grid;
    gap: 10px;
    padding: 0;
    // margin-top: 20px;
    margin-top: 2px;
    // padding-left: 3rem;
    // padding-right: 3rem;
    li {
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
        font-size: 18px;
      }
    }
}