.manualModal {
    .leftMain {
        hr {
            border: 1px solid #edeeef;
        }

        background-color: #fff;
        border: 1px solid #edeeef;
        box-shadow: 0px 4px 4px rgba(36, 36, 36, 0.06);
        border-radius: 8px;
        padding: 12px 16px;
        height: 100%;
        width: 600px;
        margin-right: 42px;

        h3 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            color: #000000;
        }
        p{
            font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 20px;
color: #6D7175;
margin-bottom: 20px;
margin-top: 15px;
        }

        .inputItem {
            margin: 15px 0;
            display: flex;
            align-items: center;
           input{
            height: 36px;
            background: #FFFFFF;
            padding: 5px;
            border: 1px solid #C9CCCF;
            border-radius: 6px;
            outline: 0;
            width: 100%;
           }
           label{
            margin-right: 10px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            white-space: nowrap;
            min-width: 150px;
           }

        }

       

        .saveRequest {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            padding: 8px 16px;
            color: #ffffff;
            background: #054D47;
            border-radius: 8px;
            margin-top: 15px;
            border: none;
            outline: none;
            cursor: pointer;
            margin-right: 0;
            margin-left: auto;

            &:hover {
                background: #021413;
            }

            &:disabled {
                cursor: not-allowed;
                opacity: 0.8;
            }
        }
                .cancel {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    padding: 8px 16px;
                    color: #ffffff;
                    background: #054D47;
                    border-radius: 8px;
                    margin-top: 15px;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    margin-right: 10px;
                   
        
                    &:hover {
                        background: #021413;
                    }
        
                    &:disabled {
                        cursor: not-allowed;
                        opacity: 0.8;
                    }
                }
                                .agree {
                                    // margin-left: 10px;
                                    font-family: "Inter";
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 20px;
                                    padding: 8px 16px;
                                    color: #ffffff;
                                    background: #00a47c;
                                    border-radius: 8px;
                                    margin-top: 15px;
                                    border: none;
                                    outline: none;
                                    cursor: pointer;
                                    
                
                                    &:hover {
                                        background: #01644b;
                                    }
                
                                    &:disabled {
                                        cursor: not-allowed;
                                        opacity: 0.8;
                                    }
                                }
    }
    .actions{
        display: flex;
        align-items: center;
    }

   .btnLoader{
    width: 117px;
    padding: 8px 16px;
    color: #ffffff;
    background: #00a47c;
    border-radius: 8px;
    margin-top: 15px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
height: 36px;
   }
}

@media all and (max-width: 1200px) {
    
    
}