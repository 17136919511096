.verifyModal {
  position: relative;
  padding: 50px 50px;
  border: 1px solid #000;
  &::after{
position: absolute;
content: '';
left: 0;
top: 0;
width: 100%;
height: 100%;
background-image: url('https://hub.360dialog.com/dist/8f08a4c63834221a1096d95540134a7c.svg');
z-index: -1;
background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .close{
      cursor: pointer;
      font-size: 13px;
    font-weight: 400;
    color: rgb(68, 66, 66);
    font-family: 'Inter';

    }
  }
  .leftMain {
    .apiDelay{
      font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 150%;
color: #347C84;
margin: 100px 0;
    }
    p{
        font-family: 'Inter';
font-style: normal;
font-size: 13px;
font-weight: 400;
color: rgb(68, 66, 66);
font-family: 'Inter';
color: rgba(153,153,153,1);;
margin: 0 0 15px 0;
    }
    .form {
      padding-top: 10px;
    }
    .inputItem {
      margin-bottom: 30px;
      position: relative;
      background-color: #fff;

      width: 100%;
      label {
        font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 20px;
color: rgba(153,153,153,1);
display: inline-block;
position: absolute;
background-color: #fff;
padding: 0 5px;
left: 15px;
    top: -10px;
      }
      input {
        background-color: #fff;
        outline: none;
        border: 1px solid rgba(229,233,242,1);
border-radius: 4px;
height: 52px;
padding: 10px 20px;
width: 100%;
&:-webkit-autofill{
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
      }
    }

    .hr {
      border-top: 1px solid rgba(192,204,218,1);
      margin: 25px 0;
    }

    background-color: #fff;
    border: 1px solid #edeeef;
    box-shadow: 0px 4px 4px rgba(36, 36, 36, 0.06);
    border-radius: 12px;
    padding: 20px 25px;
    height: 100%;
width: 100%;
    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      color: #000000;
    }

    .desc {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #44474a;
      padding: 4px;
      background: #fafbfb;
      width: fit-content;
      margin-bottom: 16px;
    }

    .saveRequest {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 8px 16px;
      color: #ffffff;
      background: #054d47;
      border-radius: 8px;
      margin-top: 15px;
      border: none;
      outline: none;
      cursor: pointer;
      margin-right: 0;
      margin-left: auto;

      &:hover {
        background: #021413;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.8;
      }
    }
    .cancel {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 8px 16px;
      color: #ffffff;
      background: #054d47;
      border-radius: 8px;
      margin-top: 15px;
      border: none;
      outline: none;
      cursor: pointer;
      margin-right: 10px;

      &:hover {
        background: #021413;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.8;
      }
    }
    .agree {
      // margin-left: 10px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 15px 20px;
      color: #ffffff;
      background: rgba(17,147,240,1);;
      border-radius: 8px;
      border: none;
      outline: none;
      cursor: pointer;
width: 100%;
      &:hover {
        background: rgb(6, 116, 194);;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.8;
      }
    }
  }
  .actions {
  
  }

  .btnLoader {
    width: 117px;
    padding: 8px 16px;
    color: #ffffff;
    background: #00a47c;
    border-radius: 8px;
    margin-top: 15px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
  }
}

@media all and (max-width: 1200px) {
}
